import React, { Component } from "react";
import { Row, Col, Button, Spinner } from "reactstrap";
import isMobile from 'is-mobile';
import platform from 'platform-detect'
import { baseUrl } from "../../config.js";
import customProtocolCheck from "custom-protocol-check";
const jwt = require("jsonwebtoken");
const { detect } = require('detect-browser');
const browser = detect();

const windowsFile = "https://imager.flexiwan.com/downloads/windows/RemoteWorkerClient-{version}.exe";
const macFile = "https://imager.flexiwan.com/downloads/macos/RemoteWorker-{version}.pkg";

class RemoteWorkerClient extends Component {

  constructor(props) {
    super(props);

    this.statusTimer = null;

    this.state = {
      configError: 0,
      downloading: false,
      mode: 'pending',
      notSupported: false,
      downloadFileUrl: null,
    }

    this.download = this.download.bind(this);
  }

  getLink = () => {
    const username = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const org = localStorage.getItem("selectedWorkspace");
    const clientVersion = localStorage.getItem("clientVersion");
    const url = baseUrl.split('https://').pop()
    const link = `RemoteWorker://${username}&${token}&${url}&${org}&${clientVersion}&flexivpn`;
    console.log('getLink: ' + link)
    return link;
  }

  isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
  }

  isFileDownloadedToday = () => {
    const localStorageItem = localStorage.getItem('clientDownloadedAutomatically');
    if (!localStorageItem) {
      return false;
    }

    const lastDownloadDate = new Date(localStorageItem)
    return this.isToday(lastDownloadDate)
  }

  componentDidMount = () => {
    document.body.classList.add('authenticated');

    // currently we support only windows and mac os and not mobile.
    const isWindows = platform.windows;
    const isMacOS = platform.macos;
    const isMobileDevice = isMobile();
    if ((!isWindows && !isMacOS) || isMobileDevice) {
      this.setState({ notSupported: true, mode: '' });
      return;
    }

    if (isMacOS || isWindows) {
      const fileURL = isMacOS? macFile : windowsFile;
      const replaced = fileURL.replace('{version}', localStorage.getItem('clientVersion'));
      this.setState({ downloadFileUrl: replaced });
    }

    // check if we need to show some errors to user, such as "no vpn servers available", etc.
    this.props.validateConfig((response, err) => {
      if (err) {
        this.setState({ mode: 'error', configError: 500 })
        return;
      }

      if (response?.valid === false) {
        this.setState({ mode: 'error', configError: response.code })
        return;
      }

      // try to load the deeplink. If it failed, it means that client is not installed and download the installation file automatically.
      const deeplink = this.getLink();
      customProtocolCheck(
        deeplink,
        () => {
          this.setState({ mode: 'notInstalled' })
          if (!this.isFileDownloadedToday()) {
            localStorage.setItem('clientDownloadedAutomatically', new Date());
            this.download()
          }
        },
        () => {
          this.setState({ mode: 'installed' })
        }, 1500
      );
    })

    if (this.statusTimer === null) {
      const timer = setInterval(
        function(that) {
          that.checkJWT();
        },
        10000,
        this
      );
      this.statusTimer = timer;
    }
    window.addEventListener("focus", this.checkJWT);
  };

  componentWillUnmount() {
    if (this.statusTimer != null) {
      clearInterval(this.statusTimer);
      this.statusTimer = null;
    }
    window.removeEventListener("focus", this.checkJWT);
  }

  checkJWT = () => {
    const token = localStorage.getItem("token");
    if (!token) this.props.logoutUser();

    const decoded = jwt.decode(token);
    if (Date.now() >= decoded.exp * 1000) {
      this.props.logoutUser();
    }
  }

  download(includeJwtInFileName = false) {
    this.setState({ downloading: true });

    const a = document.createElement('a');
    a.href = this.state.downloadFileUrl;
    a.click();

    setTimeout(() => {
      this.setState({ downloading: false });
    }, 30000)
  }

  render() {
    const bgColor = 'rgb(233 233 233)';
    const shadow = '0px 0px 11px grey';
    const chromeStyle = {
      backgroundColor: bgColor,
      padding: '12px 20px 30px 50px',
      boxShadow: shadow
    }

    const firefoxAndEdgeStyle = {
      backgroundColor: bgColor,
      padding: '10px',
      marginRight: '60px',
      marginTop: '16px',
      boxShadow: shadow
    }

    return (
      <React.Fragment>
        {
          <>
            <Row>
              <Col md={{ size: 8, offset: 2 }} className="text-center my-auto">
                <h3>Remote Worker Client</h3>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col md={{ size: 4, offset: 4 }} className="text-center my-auto">
                <img
                  className="img-responsive"
                  src={process.env.PUBLIC_URL + '/VPN-image.png'}
                  alt="vpn"
                  style={{'width': '100%'}}
                />
              </Col>
            </Row>

            { this.state.mode === 'pending' ? (
              <Row className="mt-4">
                <Col md={{ size: 4, offset: 4 }} className="text-center my-auto">
                  <Spinner color="primary" size="lg" />
                </Col>
              </Row>
            ) : (
              <>
                { this.state.mode === 'error' && this.state.configError === 500 ? (
                  <Row className="mt-3">
                    <Col md={{ size: 8, offset: 2 }} className="text-center my-auto">
                      <h6 className="alert alert-danger">Oops... Something went wrong. Please try again later</h6>
                    </Col>
                  </Row>
                ) : this.state.configError === 1000 ? (
                  <Row className="mt-3">
                    <Col md={{ size: 8, offset: 2 }} className="text-center my-auto">
                      <h6 className="alert alert-danger">Oops... Looks like the Remote Worker Server is not enabled for your company. Please contact your IT Administrator for assistance.</h6>
                    </Col>
                  </Row>
                ) : this.state.notSupported === true ? (
                  <Row className="mt-3">
                    <Col md={{ size: 8, offset: 2 }} className="text-center my-auto">
                      <h6 className="alert alert-danger">The Remote Worker Client is not supported on this device. For best experience, please use a PC or MAC</h6>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row className="mt-3">
                      <Col md={{ size: 8, offset: 2 }} className="text-center my-auto">
                        <h5>
                          { this.state.mode === 'notInstalled' ? (
                            <>
                              Once you install the <b>Remote Worker Client</b>, click <b>Launch Client</b> below
                            </>
                          ) : (
                            <>
                              Click <b>Open Remote Worker Client</b> on the dialog shown by your browser
                            </>
                          )}
                        </h5>
                      </Col>
                    </Row>

                    { this.state.mode === 'notInstalled' ? null : (
                      <Row className="mt-3">
                        <Col md={{ size: 8, offset: 2 }} className="text-center my-auto">
                          <h5>
                            If you don't see a dialog, click <b>Launch Client</b> below
                          </h5>
                        </Col>
                      </Row>
                    )}

                    <Row className="mt-1">
                      <Col md={{ size: 8, offset: 2 }} className="text-center my-auto">
                        <Button
                          size="lg"
                          onClick={() => {
                            window.location = this.getLink()
                          }}
                          style={{
                          backgroundColor: '#4A9B8F',
                          padding: '0.5rem 3rem',
                          border: 0
                          }}>
                            Launch Client
                        </Button>
                      </Col>
                    </Row>

                    <hr />

                    <Row className="mb-1">
                      <Col md={{ size: 8, offset: 2 }} className="text-center my-auto">
                        <h6>
                          Don't have the Remote Worker Client installed?
                          <span
                            onClick={() => this.download()}
                            className="ml-1 text-primary alert-link"
                            style={{
                              cursor: 'pointer',
                            }}>
                              Download Now
                          </span>
                        </h6>
                      </Col>
                    </Row>
                  </>
                ) }

              </>
            )}

          </>
        }

        {this.state.downloadFileUrl && this.state.downloading && ['chrome', 'firefox', 'edge-chromium'].find(n => n === browser.name) ? (
          <Row className={browser.name === 'chrome' ? "fixed-bottom" : "fixed-top d-flex justify-content-end"}>
            <Col
              onClick={() => this.setState( {downloading: false })}
              md={{ size: 'auto' }}
              className=""
              style={browser.name === 'chrome' ? chromeStyle : firefoxAndEdgeStyle}>
                { browser.name === 'chrome' ? (
                  <>
                    Click <b>Keep</b> when receiving notification<br/>
                    Then click <b>{this.state.downloadFileUrl.split('/').pop()}</b>
                  </>
                ) : (
                  <>
                    Click <b>Downloads</b> icon above<br/>
                    Then click <b>{this.state.downloadFileUrl.split('/').pop()}</b>
                  </>
                )}
            </Col>
          </Row>
        ) : null}
      </React.Fragment>
    );
  }
}

export default RemoteWorkerClient;
