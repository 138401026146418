import * as Action from '../Actions';

export const Main = (state={sideBarCollapsed:false, isLoading:false, redirectTo: null}, action) => {
  switch (action.type) {
    case Action.MAIN_TOPBAR_LOADING:
      return {...state, isLoading:action.isLoading};
    case Action.MAIN_REDIRECT_TO:
      return {...state, redirectTo:action.redirectTo};
    default:
      return state;
  }
};

/* Action creators */

export const mainTopbarLoading = (isLoading) => (dispatch) => {
  dispatch({
    type: Action.MAIN_TOPBAR_LOADING,
    isLoading: isLoading
  });
};

export const mainRedirectTo = (redirectTo) => (dispatch) => {
  dispatch({
    type: Action.MAIN_REDIRECT_TO,
    redirectTo: redirectTo
  });
  // Remvoe redirection
  setTimeout(function(){
    dispatch({
      type: Action.MAIN_REDIRECT_TO,
      redirectTo: null
    });
  }, 200);
};
