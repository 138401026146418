/* Main Actions */
export const MAIN_TOPBAR_LOADING = "MAIN_TOPBAR_LOADING";
export const MAIN_REDIRECT_TO = "MAIN_REDIRECT_TO";

/* Alert Messages */
export const ALERT_MSG_MESSAGE = "ALERT_MSG_MESSAGE";

/* User / Account Actions */
export const AUTH_UPDATE_JWT = "AUTH_UPDATE_JWT";
export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_FAILURE = "AUTH_LOGIN_FAILURE";
export const AUTH_LOGOUT_REQUEST = "AUTH_LOGOUT_REQUEST";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_FAILURE = "AUTH_LOGOUT_FAILURE";
export const AUTH_CREATE_REQUEST = "AUTH_CREATE_REQUEST";
export const AUTH_CREATE_SUCCESS = "AUTH_CREATE_SUCCESS";
export const AUTH_CREATE_FAILURE = "AUTH_CREATE_FAILURE";
export const AUTH_VERIFY_REQUEST = "AUTH_VERIFY_REQUEST";
export const AUTH_VERIFY_SUCCESS = "AUTH_VERIFY_SUCCESS";
export const AUTH_VERIFY_FAILURE = "AUTH_VERIFY_FAILURE";
export const AUTH_REVERIFY_REQUEST = "AUTH_REVERIFY_REQUEST";
export const AUTH_REVERIFY_SUCCESS = "AUTH_REVERIFY_SUCCESS";
export const AUTH_REVERIFY_FAILURE = "AUTH_REVERIFY_FAILURE";
export const AUTH_RESETPW_REQUEST = "AUTH_RESETPW_REQUEST";
export const AUTH_RESETPW_SUCCESS = "AUTH_RESETPW_SUCCESS";
export const AUTH_RESETPW_FAILURE = "AUTH_RESETPW_FAILURE";
export const ACCOUNT_GETALL_REQUEST = "ACCOUNT_GETALL_REQUEST";
export const ACCOUNT_GETALL_SUCCESS = "ACCOUNT_GETALL_SUCCESS";
export const ACCOUNT_GETALL_FAILURE = "ACCOUNT_GETALL_FAILURE";
export const ACCOUNT_GET_REQUEST = "ACCOUNT_GET_REQUEST";
export const ACCOUNT_GET_SUCCESS = "ACCOUNT_GET_SUCCESS";
export const ACCOUNT_GET_FAILURE = "ACCOUNT_GET_FAILURE";
export const ACCOUNT_SELECT_REQUEST = "ACCOUNT_SELECT_REQUEST";
export const ACCOUNT_SELECT_SUCCESS = "ACCOUNT_SELECT_SUCCESS";
export const ACCOUNT_SELECT_FAILURE = "ACCOUNT_SELECT_FAILURE";
export const ACCOUNT_UPD_REQUEST = "ACCOUNT_UPD_REQUEST";
export const ACCOUNT_UPD_SUCCESS = "ACCOUNT_UPD_SUCCESS";
export const ACCOUNT_UPD_FAILURE = "ACCOUNT_UPD_FAILURE";

export const SET_AUTH_METHODS = "SET_AUTH_METHODS";

export const GET_RESOURCE_REQUEST = "GET_RESOURCE_REQUEST";
export const GET_RESOURCE_SUCCESS = "GET_RESOURCE_SUCCESS";
export const GET_RESOURCE_FAILED = "GET_RESOURCE_FAILED";
