import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import { AlertMsg } from './reducers/AlertMsg';
import { Auth } from './reducers/Auth';
import { Main } from './reducers/Main';

export const Store = () => {
  const store = createStore(
    combineReducers({
      auth: Auth,
      alertmsg: AlertMsg,
      main: Main,
    }), process.env.REACT_APP_REDUX_LOGGER==="true"? applyMiddleware(thunk, logger):applyMiddleware(thunk)
  );
  return store;
};