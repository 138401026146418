import React, {Component} from 'react';
import { Alert } from 'reactstrap';

class AlertMsg extends Component {
  constructor(props) {
    super(props);

    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss() {
    this.props.alertMsgMessage({'color':null,'text':null});
  }

  render() {
    return (
      <div className='centerAbs'>
        <Alert color={this.props.alertmsg.msg.color} isOpen={this.props.alertmsg.isOpen} toggle={this.onDismiss}>
          {this.props.alertmsg.msg.text}
        </Alert>
      </div>
    );
  }
}

export default AlertMsg;