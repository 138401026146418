import { logoutUser, updateJWT } from '../redux/reducers/Auth';
import { mainTopbarLoading } from '../redux/reducers/Main';
import { alertMsgMessage } from '../redux/reducers/AlertMsg';

/*
 * Fetch common code
 * Config include:
 *  url: The request url
 *  method: 'POST', 'GET', etc
 *  headers: Object with headers, default headers will be used as
 *          'Content-Type':'application/json',
 *          'Authorization': 'Bearer ' + localStorage.getItem('token')
 *  body: the message body if exists
 *  requestCB: called upon request
 *  successCB: called upon successful response, called with the response body
 *  failureCB: called upon failed response, called with the error
 *  forbiddenCB: called upon forbidden response
 *
 */
export const FWFetch = (config) => (dispatch) => {

    dispatch(mainTopbarLoading(true));
    config.requestCB();

    let { url, method, body, headers = {} } = config;

    headers['Content-Type'] = 'application/json';
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');


    return fetch(url, {
        method,
        headers,
        body,
        credentials: 'include' // TODO: check it
    })
    .then(response => {
        if (response.status === 204) { // No-Content
            return response;
        }

        if (url.includes('resources/config') && response.status === 200) {
            return response.text()
            .then(text => {
                response.message = text.split('\n');
                return response;
            });
        }

        return response.json()
        .then(json => {
            response.message = json;
            return response;
        },
        error => {
            throw(new Error('Bad Response, Please try again'));
        });
    },
    error => {
        throw error;
    })
    .then(response => {
        if (response.ok) {
            //console.log("*** response=" + JSON.stringify(response.message));
            dispatch(mainTopbarLoading(false));
            const newJWT = response.headers.get('Refresh-JWT');
            if (newJWT!=="") dispatch(updateJWT(newJWT));
            config.successCB(response.message);
        }
        else {
            // User no longer authenticated (token has expired).
            if (response.status === 401) dispatch(logoutUser());
            let errMsg = '';
            if (response.message.error) errMsg += ': ' + response.message.error;
            throw(new Error(errMsg));
        }
    })
    .catch((error) => {
        dispatch(mainTopbarLoading(false));
        if (error.message === 'Failed to fetch') error.message = 'Connection failure';
        config.failureCB(error);
        dispatch(alertMsgMessage({'color':'danger','text':'Error ' + error.message}));
    });
};

/**
 * Find the start and end IPv4 from ip + mask
 * @param {String} ip     IPv4 address
 * @param {String} mask   Number of bit masks
 * @param {String} shift  Number of IPs to shift from start/end
 */
export const getStartEndIPv4 = (ipString, mask, shift=0) => {
    function u(n) { return n >>> 0; } // convert to unsigned
    function ip(n) {
        return [
            (n >>> 24) & 0xFF,
            (n >>> 16) & 0xFF,
            (n >>>  8) & 0xFF,
            (n >>>  0) & 0xFF
        ].join('.');
    }
    const m = ipString.split('.');
    const addr32 = m.reduce((a, o) => {
        return u(+a << 8) + +o;
    });
    const maskNum = u(~0 << (32 - +mask));
    return [ip(u(addr32 & maskNum)+shift), ip(u(addr32 | ~maskNum)-shift)];
}
