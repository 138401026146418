import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Header from "./Header";
import AlertMsg from "./AlertMsg";
import Login from "./login/Login";
import RemoteWorkerClient from "./remoteWorkerClient/RemoteWorkerClient";
import Validate from "./validate/Validate";

import { loginUser, validateUserToken, setAuthMethods, logoutUser, loginUserFM } from "../redux/reducers/Auth";
import { getClientConfig, validateConfig } from "../redux/reducers/Resources";
import { alertMsgMessage } from "../redux/reducers/AlertMsg";

const mapStateToProps = (state) => {
  return {
    main: state.main,
    auth: state.auth,
    alertmsg: state.alertmsg,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // authentication
  loginUser: (creds, success_cb) => dispatch(loginUser(creds, success_cb)),
  loginUserFM: (creds, failure_cb) => dispatch(loginUserFM(creds, failure_cb)),
  logoutUser: () => dispatch(logoutUser()),
  validateUserToken: () => dispatch(validateUserToken()),
  setAuthMethods: (arr) => dispatch(setAuthMethods(arr)),

  // general
  alertMsgMessage: (msg) => dispatch(alertMsgMessage(msg)),

  // config
  getClientConfig: (callback) => dispatch(getClientConfig(callback)),
  validateConfig: (callback) => dispatch(validateConfig(callback)),
});

// FWRoute checks:
// 1) If message was displayed for more than 10 seconds, it's removed in the next render
// 2) Allow the route only if user authenticated or route doesn't require auth,
//    otherwise, redirect to the login page
// 3) If user authenticated but no organization was set, redirect to add-organization
const FWRoute = ({ render, parentProps, ...rest }) => {
  // Allow at least 10 sec to see the message open. Route after that will cause message to disappear
  const curTime = new Date();
  if (parentProps.alertmsg.isOpen && curTime.getTime() > parentProps.alertmsg.time + 10000) {
    parentProps.alertMsgMessage({ color: null, text: null });
  }

  // If redirection set, use it. Otherwise use null = render normally
  const splitPath = parentProps.main.redirectTo ? parentProps.main.redirectTo.split("?") : null;
  let redirectTo = splitPath ? splitPath[0] : null;
  let search = splitPath && splitPath.length > 1 ? "?" + splitPath[1] : "";

  // Override redirection in the following cases:
  // 1. If route requires auth and not authenticated - redirect to login
  if (!rest.allowedNoAuth && !parentProps.auth.isAuthenticated) {
    redirectTo = "/login";
  }

  if (parentProps.location.search.includes("redirect=auth")) {
    const urlParams = new URLSearchParams(parentProps.location.search);
    const validateToken = urlParams.get("token");
    if (validateToken) {
      localStorage.setItem("validateToken", validateToken);
      redirectTo = "/validate";
    }
  }

  return (
    <Route
      {...rest}
      render={
        redirectTo && redirectTo !== parentProps.location.pathname
          ? (props) => (
              <Redirect
                to={{
                  pathname: redirectTo,
                  search: search,
                  state: { from: props.location },
                }}
              />
            )
          : render
      }
    />
  );
};

class Main extends Component {
  render() {
    return (
      <div>
        <Header
          isLoading={this.props.main.isLoading}
          auth={this.props.auth}
          logoutUser={this.props.logoutUser}
        />
        <div id="mainContainer" className="container">
          <div className="mainContent">
            <AlertMsg
              alertmsg={this.props.alertmsg}
              alertMsgMessage={this.props.alertMsgMessage}
            />
            <Switch>
              <FWRoute
                parentProps={this.props}
                allowedNoAuth
                path="/login"
                render={(props) => (
                  <Login
                    methods={this.props.auth.methods}
                    setAuthMethods={this.props.setAuthMethods}
                    loginUser={this.props.loginUser}
                    logoutUser={this.props.logoutUser}
                    loginUserFM={this.props.loginUserFM}
                    isAuthenticated={this.props.auth.isAuthenticated}
                    alertMsgMessage={this.props.alertMsgMessage}
                  />
                )}
              />

              <FWRoute
                parentProps={this.props}
                allowedNoAuth
                path="/validate"
                render={(props) => (
                  <Validate validateUserToken={this.props.validateUserToken} />
                )}
              />

              <FWRoute
                parentProps={this.props}
                path="/home"
                render={(props) => (
                  <RemoteWorkerClient
                    isAuthenticated={this.props.auth.isAuthenticated}
                    getClientConfig={this.props.getClientConfig}
                    getExeFile={this.props.getExeFile}
                    validateConfig={this.props.validateConfig}
                    logoutUser={this.props.logoutUser}
                  />
                )}
              />
              <Redirect to={{ ...this.props.location, pathname: "/login" }} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
