import React, { Component } from "react";

class Validate extends Component {
  componentDidMount() {
    this.props.validateUserToken();
  }

  render() {
    return (
      <h3 className="pleasewait">
        Please wait
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </h3>
    );
  }
}

export default Validate;
