import * as Actions from "../Actions";
import { FWFetch } from "../../utils/Network";
import { mainRedirectTo } from "./Main";
import { baseUrl } from "../../config.js";

const jwt = require("jsonwebtoken");

// If token does not exist or expired return false, otherwise true
const parseToken = () => {
  const token = localStorage.getItem("token");

  const nullRet = {
    userId: null,
    isAuthenticated: false,
    expiration: 0,
  };
  if (!token) return nullRet;

  const decodedToken = jwt.decode(token, { complete: true });

  if (!decodedToken) return nullRet;

  // Log the user out if both the access
  // and refresh tokens are expired
  const dateNow = new Date();
  if (decodedToken.payload.exp * 1000 < dateNow.getTime()) {
    return nullRet;
  }

  return {
    userId: decodedToken.payload._id,
    isAuthenticated: true,
    expiration: decodedToken.payload.exp,
  };
};

export const Auth = (
  state = {...parseToken(),
    token: localStorage.getItem("token"),
    user: localStorage.getItem("user"),
    methods: []
  },
  action) => {
  switch (action.type) {
    case Actions.AUTH_UPDATE_JWT:
      return {
        ...state,
        token: action.token,
        userId: action.userId,
        expiration: action.expiration,
      };
    case Actions.AUTH_LOGIN_REQUEST:
      return { ...state, userId:null, isAuthenticated:false, user:null, token:null,
        expiration: 0 };
    case Actions.AUTH_LOGIN_SUCCESS:
      return { ...state, isAuthenticated: true, user: action.name };
    case Actions.AUTH_LOGIN_FAILURE:
      return { ...state, userId:null, isAuthenticated: false, user: null, token: null,
        expiration: 0 };
    case Actions.AUTH_LOGOUT_REQUEST:
      return state;
    case Actions.AUTH_LOGOUT_SUCCESS:
      return { ...state, userId:null, isAuthenticated: false, token: null, user: null,
        expiration: 0 };

    case Actions.AUTH_CREATE_REQUEST:
      return state;
    case Actions.AUTH_CREATE_SUCCESS:
      return state;
    case Actions.AUTH_CREATE_FAILURE:
      return state;

    case Actions.AUTH_VERIFY_REQUEST:
      return state;
    case Actions.AUTH_VERIFY_SUCCESS:
      return state;
    case Actions.AUTH_VERIFY_FAILURE:
      return state;

    case Actions.AUTH_REVERIFY_REQUEST:
      return state;
    case Actions.AUTH_REVERIFY_SUCCESS:
      return state;
    case Actions.AUTH_REVERIFY_FAILURE:
      return state;

    case Actions.AUTH_RESETPW_REQUEST:
      return state;
    case Actions.AUTH_RESETPW_SUCCESS:
      return state;
    case Actions.AUTH_RESETPW_FAILURE:
      return state;

    case Actions.SET_AUTH_METHODS: {
      return { ...state, methods: action.methods }
    }

    default:
      return state;
  }
};

/* Action Creators */
export const updateJWT = token => dispatch => {
  const decodedToken = jwt.decode(token);
  if (!decodedToken) return;
  // save the token in local storage
  localStorage.setItem("token", token);
  dispatch({
    type: Actions.AUTH_UPDATE_JWT,
    token: token,
    expiration: decodedToken.exp,
    userId: decodedToken._id
  });
};

export const requestLogin = creds => dispatch => {
  dispatch({
    type: Actions.AUTH_LOGIN_REQUEST,
    creds
  });
};

export const receiveLogin = response => dispatch => {
  dispatch({
    type: Actions.AUTH_LOGIN_SUCCESS,
    name : response.name
  });
};

export const setAuthMethods = authorizationUris => dispatch => {
  dispatch({
    type: Actions.SET_AUTH_METHODS,
    methods : authorizationUris
  });
};

export const loginError = error => dispatch => {
  dispatch({
    type: Actions.AUTH_LOGIN_FAILURE
  });
};

export const loginUser = (creds, success_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "login",
      method: "POST",
      body: JSON.stringify(creds),
      requestCB: () => {
        dispatch(requestLogin(creds));
      },
      successCB: response => {
        localStorage.setItem('selectedWorkspace', creds.workspaceName);

        // store the selected workspace in url *without* reloading the page
        // This in order to keep it open after refresh.
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('workspace', creds.workspaceName);
        window.history.replaceState(null, null, "login?" + urlParams)

        const authorizationUris = response.authorizationUris;
        dispatch(setAuthMethods(authorizationUris))
        if (success_cb) success_cb(response);
      },
      failureCB: error => {
        console.log(error.message)
        dispatch(loginError(error))
      }
    })
  );
};

export const loginUserFM = (cred, failure_cb) => dispatch => {
  return dispatch(
    FWFetch({
      url: baseUrl + "login/flexiManage",
      method: "POST",
      body: JSON.stringify(cred),
      requestCB: () => {
        dispatch(requestLogin());
      },
      successCB: response => {
        localStorage.setItem('selectedWorkspace', cred.workspace);
        localStorage.setItem("user", response.name);
        localStorage.setItem("email", response.email);
        localStorage.setItem("clientVersion", response.clientVersion);
        dispatch(receiveLogin(response));
        dispatch(mainRedirectTo('/home'));
      },
      failureCB: error => {
        console.log(error.message)
        dispatch(mainRedirectTo('/login'));
        dispatch(loginError(error))
        failure_cb()
      }
    })
  );
};

export const validateUserToken = () => dispatch => {
  const token = localStorage.getItem('validateToken')

  if (!token || token === '') return dispatch(mainRedirectTo('/login'));

  const validateToken = {
    token: token
  };

  return dispatch(
    FWFetch({
      url: baseUrl + "validateTmpToken",
      method: "POST",
      body: JSON.stringify(validateToken),
      requestCB: () => {
        dispatch(requestLogin(validateToken));
      },
      successCB: response => {
        // now validateToken exchanged with JWT token
        localStorage.removeItem("validateToken");

        localStorage.setItem("user", response.name);
        localStorage.setItem("email", response.email);
        localStorage.setItem("clientVersion", response.clientVersion);
        dispatch(receiveLogin(response));
        dispatch(mainRedirectTo('/home'));
      },
      failureCB: error => {
        localStorage.removeItem("validateToken");
        dispatch(loginError(error))
        dispatch(mainRedirectTo('/login'));
      }
    })
  );
};

// Logout
export const requestLogout = () => {
  return {
    type: Actions.AUTH_LOGOUT_REQUEST
  };
};

export const receiveLogout = () => {
  return {
    type: Actions.AUTH_LOGOUT_SUCCESS
  };
};

export const logoutUser = () => dispatch => {
  dispatch(receiveLogout());
  dispatch(requestLogout());
  dispatch(setAuthMethods([]));
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("email");
  localStorage.removeItem("validateToken");
  localStorage.removeItem("selectedWorkspace");
  localStorage.removeItem("clientVersion");
  dispatch(mainRedirectTo('/login'));
};