import * as Action from '../Actions';

export const AlertMsg = (state={isOpen:false, msg:{'color':'info','text':''}, time:null}, action) => {
  switch (action.type) {
    case Action.ALERT_MSG_MESSAGE:
      if (action.msg.color == null || action.msg.text == null)
        return {...state, isOpen:false, msg:{'color':'info','text':''}, time:null};
      else
        return {...state, isOpen:true, msg:action.msg, time:action.time};

    default:
      return state;
  }
};

/* Action creators */
export const alertMsgMessage = (msg) => {
  const curTime = new Date();
  return ({
    type: Action.ALERT_MSG_MESSAGE,
    msg: msg,
    time: curTime.getTime()
  });
}