import React, { Component } from "react";
import { Navbar, NavbarBrand, Button } from "reactstrap";
import logoImg from "../assets/images/logot.png";
import logoutImg from "../assets/images/icn_logout.png";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = { accountDropdown: false };
    this.handleLogout = this.handleLogout.bind(this)
  }

  handleLogout() {
    this.props.logoutUser();
  }

  render() {
    const navbarClassesNames = [];
    if (this.props.isLoading) {
      navbarClassesNames.push("loading");
    }

    return (
      <React.Fragment>
        <Navbar id="topbar" expand>
          <div>
            <NavbarBrand href="/home">
              <img src={logoImg} height="24" width="127" alt="FlexiWAN" />
            </NavbarBrand>
          </div>

          {
            this.props.auth.isAuthenticated ? (
              <>
              <div className="ml-auto d-flex justify-content-between align-items-center">
                  <div className="mr-4">{this.props.auth.user} ({localStorage.getItem('selectedWorkspace')})</div>
                </div>

                <Button onClick={this.handleLogout} onMouseDown={e => e.preventDefault()}>
                  <img src={logoutImg} height="25" width="25" alt="Logout" />
                </Button>
              </>
            ) : (
              <div />
            )
          }
          <div className={navbarClassesNames.join(" ")} />
        </Navbar>
      </React.Fragment>
    );
  }
}

export default Header;
