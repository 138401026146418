import * as Actions from "../Actions";
import { FWFetch } from "../../utils/Network";
import { baseUrl } from "../../config.js";

export const Resources = (state = { clientFile: null }, action) => {
  switch (action.type) {
    case Actions.GET_RESOURCE_SUCCESS:
      return { ...state, clientFile: action.data };
    default:
      return state;
  }
};

export const receiveClientFile = (response) => (dispatch) => {
  dispatch({
    type: Actions.GET_RESOURCE_SUCCESS,
    data: response.data,
  });
};

export const getClientConfig = callback => (dispatch) => {
  return dispatch(
    FWFetch({
      url: baseUrl + "resources/config?responseType=json",
      method: "GET",
      requestCB: () => {
      },
      successCB: (response) => {
        if (callback) {
          callback(response, null);
        }
      },
      failureCB: (error) => {
        if (callback) {
          callback(null, error.message);
        }
      },
    })
  );
};

export const validateConfig = (callback) => (dispatch) => {
  return dispatch(
    FWFetch({
      url: baseUrl + "resources/validateConfig",
      method: "GET",
      requestCB: () => {
      },
      successCB: (response) => {
        if (callback) {
          callback(response, null);
        }
      },
      failureCB: (error) => {
        if (callback) {
          callback(null, error.message);
        }
      },
    })
  );
};

// export const getExeFile = (callback) => (dispatch) => {
//   return dispatch(
//     FWFetch({
//       url: baseUrl + "resources/download",
//       method: "GET",
//       requestCB: () => {
//       },
//       successCB: (response) => {
//         callback(response, null);
//       },
//       failureCB: (error) => {
//         callback(null, error.message);
//       },
//     })
//   );
// };