import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Card,
  CardBody,
  CardTitle,
  Row, Col, Modal, ModalHeader, ModalBody, InputGroup
} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { Redirect } from "react-router-dom";
import { captchaSiteKey } from '../../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css'

let sessionTimeout = null;

class VpnLogin extends Component {
  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();

    this.state = {
      selected: null,
      loginWithFlexiwan: false,
      captchaToken: ''
    }

    this.handleLogin = this.handleLogin.bind(this);
    this.handleLoginFM = this.handleLoginFM.bind(this);
    this.handleSigningWithOtherAccount = this.handleSigningWithOtherAccount.bind(this);
    this.verifyCaptcha = this.verifyCaptcha.bind(this);
  }

  componentDidMount() {
    document.body.classList.remove('authenticated');
    const urlParams = new URLSearchParams(window.location.search);
    const errMessage = urlParams.get("err");
    if (errMessage) {
      urlParams.delete('err');
      this.props.alertMsgMessage({'color':'danger','text': errMessage});
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    }

    // handled direct link
    const workspace = urlParams.get('workspace');
    const useFlexiManageAuth = urlParams.get('authMethod') === "flexiwan";

    if (workspace && !useFlexiManageAuth) {
      const cred = {
        workspaceName: workspace
      };
      this.props.loginUser(cred, this.onLoginCallback);
      this.setState({ selected: workspace });
      return;
    } else if (workspace && useFlexiManageAuth) {
      this.setState({ loginWithFlexiwan: true, selected: workspace })
      return;
    }
  }

  onLoginCallback = (response) => {
    if (response?.loginSessionExpiration) {
      if (sessionTimeout) {
        clearTimeout(sessionTimeout);
      }

      sessionTimeout = setTimeout(() => {
        this.setState({selected: null})
        this.props.logoutUser();

        toast('Session expired!', {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }, response.loginSessionExpiration * 1000)
    }
  }

  componentWillUnmount() {
    if (sessionTimeout) {
      clearTimeout(sessionTimeout);
      sessionTimeout = null;
    }
  }

  handleLogin(event) {
    event.preventDefault();

    const cred = {
      workspaceName: this.workspace.value,
    };

    this.props.loginUser(cred, response => {
      this.onLoginCallback(response)
      this.setState({ selected: cred.workspaceName })
    });
  }

  handleLoginFM(event) {
    event.preventDefault();
    const data = {
      username: this.fmUserName.value,
      password: this.fmPassword.value,
      workspace: this.state.selected,
      captcha: this.state.captchaToken
    };
    this.props.loginUserFM(data, ()=> {   // failure callback
      // Nothing to do here since the modal is closed when clicking on login
    });
    this.setState({loginWithFlexiwan: false});
  }

  verifyCaptcha(token) {
    this.setState({captchaToken: token});
  }

  handleSigningWithOtherAccount() {
    window.event.preventDefault();

    // remove the selected workspace from url *without* reloading the page
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('workspace');
    window.history.replaceState(null, null, "login")

    this.props.setAuthMethods([]);
    localStorage.removeItem('selectedWorkspace')
    this.setState({selected: null})
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/home" />;
    }

    return (
      <React.Fragment>
        <Row>
          <Col md={{ size: 4, offset: 4}} sm={12}>
            <Card>
              <CardBody>
                <CardTitle>Login to Remote Worker Workspace</CardTitle>
                <hr />
                <Form onSubmit={this.handleLogin}>
                  {
                    this.state.selected && this.state.selected !== "" ?
                    (
                      <Label className="text-center" htmlFor="" md={12}>
                        Welcome to the <b>{this.state.selected}</b> workspace
                      </Label>
                    )
                    :
                    (
                      <FormGroup>
                        <Row>
                          <Label htmlFor="workspace" md={5}>Workspace name</Label>
                          <Col md={7}>
                            <Input
                              id="workspace"
                              name="workspace"
                              required
                              autoComplete="off"
                              innerRef={input => (this.workspace = input)}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    )
                  }

                   <FormGroup>
                     <Row>
                       <Col md={12}>
                        {
                          this.state.selected && this.state.selected !== "" ?
                          null
                          :
                          (
                            <Button
                              style={{width: '100%'}}
                              type="submit"
                              value="submit"
                              color="primary"
                            >
                              Login
                            </Button>
                          )
                        }
                      </Col>
                    </Row>
                  </FormGroup>

                  {this.props.methods.length ? (
                    <>
                      <Row className="align-items-center justify-content-around">
                        {
                          this.props.methods.find(m => m.type === "gsuite") ? (
                            <Col className="text-center">
                              <a href={this.props.methods.find(m => m.type === "gsuite").uri}>
                                <img className="img-fluid" style={{height: '44px'}} alt="sign-in with Google" src={process.env.PUBLIC_URL + '/btn_google_signin_dark_normal_web.png'}>
                                </img>
                              </a>
                            </Col>
                          ) : null
                        }

                        {
                          this.props.methods.find(m => m.type === "office365") ? (
                            <Col className="text-center">
                              <a href={this.props.methods.find(m => m.type === "office365").uri}>
                                <img className="img-fluid" alt="sign-in with Microsoft" src={process.env.PUBLIC_URL + '/ms-symbollockup_signin_light.png'}></img>
                              </a>
                            </Col>
                          ) : null
                        }
                      </Row>
                    </>
                  ) : null}
                </Form>
                <hr className=""/>

                <div className="bottom-card-message">
                  <div style={{'color': '#43b394', fontSize: '0.8rem', 'cursor': 'pointer'}}>
                    { this.state.selected ? (
                      <span onClick={this.handleSigningWithOtherAccount}>Log in to another workspace</span>
                    ) : null}
                    <span style={{'float':'right'}}>
                      <a href="mailto:yourfriends@flexiwan.com">Contact us</a>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal isOpen={this.state.loginWithFlexiwan} toggle={() => this.setState({ loginWithFlexiwan: !this.state.loginWithFlexiwan })}>
          <ModalHeader toggle={() => this.setState({ loginWithFlexiwan: false })}>Login with flexiManage</ModalHeader>

          <ModalBody>
            <Form onSubmit={this.handleLoginFM}>
              <InputGroup>
              <Input
                id="username"
                name="username"
                type="email"
                required
                placeholder="User Name"
                autoComplete="off"
                innerRef={input => (this.fmUserName = input)}
              />
              </InputGroup>
              <br />
              <InputGroup>
              <Input
                id="password"
                type="password"
                name="password"
                required
                placeholder="Password"
                autoComplete="off"
                innerRef={input => (this.fmPassword = input)}
              />
              </InputGroup>
              <br />

              { captchaSiteKey !== '' ?
                <Row className="form-group">
                  <Col md={12}>
                    <ReCAPTCHA style={{'float':'right'}}
                      ref={this.recaptchaRef}
                      sitekey={captchaSiteKey}
                      onChange={value => this.verifyCaptcha(value)}
                    />
                  </Col>
                </Row>:null}
              <br/>

              <Button type="submit" color="primary">Login</Button>
              <Button type="button" className="float-right" color="default" onClick={() => this.setState({ loginWithFlexiwan: false })}>Cancel</Button>
            </Form>
          </ModalBody>
        </Modal>

        <ToastContainer />
      </React.Fragment>
    );
  }
}
export default VpnLogin;
